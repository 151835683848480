import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import { Content } from "../components/globals"

const Wrapper = styled.div`
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  @media screen and (max-width: 991px) {
    margin: 0em auto;
    padding: 0;
  }
`;

const StyledContent = styled(Content)`
  margin: 1em auto;
  letter-spacing:1px;
  @media screen and (max-width: 991px) {
    margin: 1em auto;
    padding: 5%;
    & p {
        font-size: .8em;
    }
    & h1{
        font-size:1.5rem;
    }
    & h2{
        font-size:1.2rem;
    }
    & h3{
        font-size:1rem;
    }
    & h4{
        font-size:.8rem;
    }
  }
`;
const Title = styled.h1`
  margin:0;
  letter-spacing:1.6px;
  @media screen and (max-width: 991px){
      font-size: 1.8em;
  }
`;
const ActualTitle = styled.div`
    z-index:2;
    position:absolute;
    padding: 0 1em;
    bottom:-1em;
    left:0;
    right:0;
`;
const TitleWrapper = styled.div`
  padding: 5%;
  position:relative;
  height:150px;
  display:flex;
  align-items: flex-end;
  background:white;
  @media screen and (min-width:992px){
      margin-bottom:5em;
      padding: 1%;
  }
`;
const BackgroundTitle = styled.h2`
    font-size: 4em;
    color:#0B0C14;
    font-weight:700;
    margin: 0;
    opacity: .05; 
    max-width:100vw;
    max-height:100%;
    overflow:hidden;
    @media screen and (max-width:991px){
        max-width:150vw;
        font-size: 3em;
    }  
`;
export default ({ data }) => {
  const {
    allStrapiPage: {
      edges: {
        0: { node: { title, content } }
      }
    }
  } = data;
  return (
    <Layout title={title} description={content.data.content.substr(0, 70)}>
      <Wrapper>
        <TitleWrapper>
          <ActualTitle>
            <Title>{title}</Title>
          </ActualTitle>
          <BackgroundTitle>{title}</BackgroundTitle>
        </TitleWrapper>
        <StyledContent>{content.data.content}</StyledContent>
      </Wrapper>
    </Layout>
  );
};
export const query = graphql`
  query($strapi_id: Int!) {
    allStrapiPage(filter: { strapi_id: { eq: $strapi_id } }) {
      edges {
        node {
          title
          content {
            data {
              content
            }
          }
        }
      }
    }
  }
`;
